import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import textsService from 'services/textsService';
import Button from '../../button';
import AppComponents from '../../../../../../constants/components';

const RequestVisit = ({
  schoolId,
  disabled,
}) => {
  const navigate = useNavigate();

  const calculatePageName = () => AppComponents.inferFromUrlPath(window.location.pathname)?.name
    || window.location.pathname;

  const onClickRequestInterview = (theSchoolId) => {
    const schoolPath = `/school/${theSchoolId}?action=visit&from=${calculatePageName()}`;
    const tabOpenedSuccessfully = window.open(schoolPath, '_blank');
    if (!tabOpenedSuccessfully) {
      navigate(schoolPath);
    }
  };

  const interactionsDisabledText = textsService.getLocalizedText(
    textsService.TEXT_KEYS.CARD_CTA_DISABLED,
  );

  return (
    <>
      <Button
        hirachy="primary"
        size="small"
        onClick={
          () => onClickRequestInterview(schoolId)
        }
        disabled={!!disabled}
        title={disabled ? interactionsDisabledText : undefined}
      >
        Solicitar entrevista
      </Button>
    </>
  );
};

RequestVisit.propTypes = {
  schoolId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

RequestVisit.defaultProps = {
  disabled: false,
};

export default RequestVisit;
