import React from 'react';
import textsService from 'services/textsService';

import paths from '../../routes/paths';

import './styles.css';

const VacantestBanner = () => (
  <aside className="vacantestbanner">
    <h1>
      {textsService.getLocalizedText(textsService.TEXT_KEYS.LANDING_HERO_TITLE_1)}
      {' '}
      {textsService.getLocalizedText(textsService.TEXT_KEYS.LANDING_HERO_TITLE_2)}
      {' '}
      {textsService.getLocalizedText(textsService.TEXT_KEYS.LANDING_HERO_TITLE_3)}
    </h1>
    <h3>
      {textsService.getLocalizedText(textsService.TEXT_KEYS.LANDING_HERO_SUBTITLE_1)}
      {' '}
      {textsService.getLocalizedText(textsService.TEXT_KEYS.LANDING_HERO_SUBTITLE_2)}
    </h3>
    <button
      type="button"
      onClick={() => window.open(paths.NEW_EXPERIENCE, '_blank')}
      className="vacantest-button"
    >
      {textsService.getLocalizedText(textsService.TEXT_KEYS.LANDING_HERO_CTA)}
    </button>
  </aside>
);

export default VacantestBanner;
