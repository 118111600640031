import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import textsService from 'services/textsService';
import BenefitsModal from '../benefits-modal';
import Modal from '../modal';

import './benefits-banner-styles.scss';

const BenefitsBanner = ({ openFromOutside, setOpenFromOutside }) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (openFromOutside) {
      setOpenModal(true);
      if (setOpenFromOutside) setOpenFromOutside(false);
    }
  }, [openFromOutside]);

  return (
    <div className="experience-benefits-banner">
      <div className="experience-benefits-banner__image__container">
        <img
          src="https://vacanted-assets-dev.s3.amazonaws.com/icons/icon_gift_color.png"
          className="experience-benefits-banner__image"
          alt="beneficios"
        />
      </div>
      <div className="experience-benefits-banner__content__container">
        <div className="experience-benefits-banner__content">
          <div className="experience-benefits-banner__title">
            {textsService.getLocalizedText(textsService.TEXT_KEYS.LANDING_BENEFITS_TITLE)}
          </div>
          <div className="experience-benefits-banner__subtitle">
            {textsService.getLocalizedText(textsService.TEXT_KEYS.LANDING_BENEFITS_SUBTITLE)}
          </div>
        </div>
        <button type="button" onClick={() => setOpenModal(true)} className="experience-benefits-banner__button">
          Ver beneficios
        </button>
      </div>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)} size="large">
        <BenefitsModal />
      </Modal>
    </div>
  );
};

BenefitsBanner.propTypes = {
  openFromOutside: PropTypes.bool,
  setOpenFromOutside: PropTypes.func,
};

BenefitsBanner.defaultProps = {
  openFromOutside: false,
  setOpenFromOutside: null,
};

export default BenefitsBanner;
