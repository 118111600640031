import React, { useState, useEffect } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { useLocation } from 'react-router-dom';
import textsService from 'services/textsService';

const showOnPages = [/^\/$/, /^\/school\/\d+/, /^\/colegios\/.*/];

const WhatsAppWidget = () => {
  const location = useLocation();
  const [shouldShowWidget, setShouldShowWidget] = useState(false);

  useEffect(() => {
    setShouldShowWidget(
      showOnPages.some((pageRegex) => location.pathname.match(pageRegex)),
    );
  }, [location.pathname]);

  if (!shouldShowWidget) return null;

  return (
    <FloatingWhatsApp
      phoneNumber="59894525220"
      accountName="VacantED"
      avatar="https://images.vacanted.com.uy/logos/vacanted_logo_min.png"
      statusMessage=""
      allowClickAway
      chatMessage={textsService.getLocalizedText(textsService.TEXT_KEYS.WHATSAPP_WIDGET_MESSAGE)}
      placeholder="Escribí tu consulta..."
    />
  );
};

export default WhatsAppWidget;
