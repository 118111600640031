import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ExprienceMain from '../../../components/main/view';
import ExperienceButton from '../../../components/button';

import './styles.scss';

const CongratsExperienceFinish = ({ hasBenefits }) => {
  const navigate = useNavigate();

  return (
    <ExprienceMain>
      <div className="experience-congrats">
        <svg
          className="experience-congrats__icon"
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.6106 45.5817L24.024 36.9038C23.3542 36.2949 22.5321 35.9904 21.5577 35.9904C20.5833 35.9904 19.7612 36.3253 19.0913 36.9952C18.4215 37.6651 18.0865 38.4872 18.0865 39.4615C18.0865 40.4359 18.4215 41.2276 19.0913 41.8365L30.3269 53.1635C30.9248 53.8333 31.6833 54.1683 32.6023 54.1683C33.5213 54.1683 34.3157 53.8333 34.9856 53.1635L57 31.149C57.6699 30.4792 58.0048 29.6571 58.0048 28.6827C58.0048 27.7083 57.6699 26.8558 57 26.125C56.2692 25.516 55.4014 25.2268 54.3966 25.2572C53.3918 25.2877 52.5849 25.6074 51.976 26.2163L32.6106 45.5817ZM38 76C32.7847 76 27.8679 74.9946 23.2494 72.9837C18.631 70.9729 14.6069 68.2526 11.1771 64.8229C7.74737 61.3931 5.02708 57.369 3.01625 52.7506C1.00542 48.1321 0 43.2153 0 38C0 32.7692 1.00721 27.8376 3.02164 23.2055C5.03607 18.5733 7.76123 14.5372 11.1971 11.0974C14.633 7.65746 18.6571 4.94792 23.2693 2.96875C27.8816 0.989583 32.7918 0 38 0C43.2302 0 48.1612 0.989583 52.7929 2.96875C57.4245 4.94792 61.4607 7.65785 64.9014 11.0986C68.3421 14.5393 71.0521 18.5762 73.0312 23.2095C75.0104 27.8428 76 32.7755 76 38.0076C76 43.2397 75.0104 48.1546 73.0312 52.7524C71.0521 57.3502 68.3425 61.367 64.9026 64.8029C61.4628 68.2388 57.4267 70.9639 52.7945 72.9784C48.1623 74.9928 43.2308 76 38 76Z"
            fill="#33A58A"
          />
        </svg>
        <div
          className="experience-congrats__message"
        >
          ¡Finalizaste tu búsqueda!
        </div>
        <div
          className="experience-congrats__submessage"
        >
          {hasBenefits
            ? 'Te enviaremos un correo electrónico con toda la información para acceder a los beneficios de elegir centro educativo en VacantED.'
            : 'Podés crear otra para obtener nuevas recomendaciones.'}
        </div>
        <ExperienceButton hirachy="primary" className="experience-congrats__action" size="medium" onClick={() => navigate('/')}>
          Ir al inicio
        </ExperienceButton>
      </div>
    </ExprienceMain>
  );
};

CongratsExperienceFinish.propTypes = {
  hasBenefits: PropTypes.bool.isRequired,
};

export default CongratsExperienceFinish;
