import countryService from './countryService';

const TEXT_KEYS = {
  INTERACTION_PENDING_VALIDATION: 'INTERACTION_PENDING_VALIDATION',
  INTERACTION_ADDED_CANDIDATE: 'INTERACTION_ADDED_CANDIDATE',
  INTERACTION_CREATED: 'INTERACTION_CREATED',
  INTERACTION_FAILED: 'INTERACTION_FAILED',
  INTERACTION_ALREADY_CONTACTED: 'INTERACTION_ALREADY_CONTACTED',
  INTERACTION_ALREADY_CHATTING: 'INTERACTION_ALREADY_CHATTING',
  INTERACTION_ALREADY_REQUESTED_INTERVIEW: 'INTERACTION_ALREADY_REQUESTED_INTERVIEW',

  HEADER_SEARCH_PLACEHOLDER: 'HEADER_SEARCH_PLACEHOLDER',
  HEADER_SEARCH_SCHOOL_RESULTS_TITLE: 'HEADER_SEARCH_SCHOOL_RESULTS_TITLE',
  HEADER_SEARCH_LOCATION_RESULTS_CTA: 'HEADER_SEARCH_LOCATION_RESULTS_CTA',
  HEADER_LOGIN_SCHOOL: 'HEADER_LOGIN_SCHOOL',

  FOOTER_TEXT: 'FOOTER_TEXT',

  LANDING_HERO_TITLE_1: 'LANDING_HERO_TITLE_1',
  LANDING_HERO_TITLE_2: 'LANDING_HERO_TITLE_2',
  LANDING_HERO_TITLE_3: 'LANDING_HERO_TITLE_3',
  LANDING_HERO_SUBTITLE_1: 'LANDING_HERO_SUBTITLE_1',
  LANDING_HERO_SUBTITLE_2: 'LANDING_HERO_SUBTITLE_2',
  LANDING_HERO_CTA: 'LANDING_HERO_CTA',
  LANDING_BENEFITS_TITLE: 'LANDING_BENEFITS_TITLE',
  LANDING_BENEFITS_SUBTITLE: 'LANDING_BENEFITS_SUBTITLE',
  LANDING_HOWITWORKS_RECOMMENDATIONS_TITLE: 'LANDING_HOWITWORKS_RECOMMENDATIONS_TITLE',
  LANDING_HOWITWORKS_RECOMMENDATIONS_SUBTITLE: 'LANDING_HOWITWORKS_RECOMMENDATIONS_SUBTITLE',
  LANDING_HOWITWORKS_CANDIDATES_TITLE: 'LANDING_HOWITWORKS_CANDIDATES_TITLE',
  LANDING_HOWITWORKS_CANDIDATES_SUBTITLE: 'LANDING_HOWITWORKS_CANDIDATES_SUBTITLE',

  WHATSAPP_WIDGET_MESSAGE: 'WHATSAPP_WIDGET_MESSAGE',

  SCHOOL_LANDING_HEADER: 'SCHOOL_LANDING_HEADER',
  SCHOOL_LANDING_TITLE: 'SCHOOL_LANDING_TITLE',
  SCHOOL_LANDING_SUBTITLE: 'SCHOOL_LANDING_SUBTITLE',

  SCHOOL_DIRECTORY_TITLE: 'SCHOOL_DIRECTORY_TITLE',
  SCHOOL_DIRECTORY_SUBTITLE: 'SCHOOL_DIRECTORY_SUBTITLE',
  SCHOOL_DIRECTORY_EMPTY_MESSAGE: 'SCHOOL_DIRECTORY_EMPTY_MESSAGE',

  CARD_CTA_DISABLED: 'CARD_CTA_DISABLED',

  EXPERIENCE_FLOW_WELCOME: 'EXPERIENCE_FLOW_WELCOME',
  EXPERIENCE_FLOW_STEP_1_TITLE: 'EXPERIENCE_FLOW_STEP_1_TITLE',
  EXPERIENCE_FLOW_STEP_1_SUBTITLE: 'EXPERIENCE_FLOW_STEP_1_SUBTITLE',
  EXPERIENCE_FLOW_STEP_2_TITLE: 'EXPERIENCE_FLOW_STEP_2_TITLE',
  EXPERIENCE_FLOW_STEP_2_SUBTITLE: 'EXPERIENCE_FLOW_STEP_2_SUBTITLE',
  EXPERIENCE_FLOW_STEP_3_TITLE: 'EXPERIENCE_FLOW_STEP_3_TITLE',
  EXPERIENCE_FLOW_STEP_3_SUBTITLE: 'EXPERIENCE_FLOW_STEP_3_SUBTITLE',

  EXPERIENCE_FLOW_START_YEAR_QUESTION_TITLE: 'EXPERIENCE_FLOW_START_YEAR_QUESTION_TITLE',
  EXPERIENCE_FLOW_OWN_CANDIDATES_QUESTION_TITLE: 'EXPERIENCE_FLOW_OWN_CANDIDATES_QUESTION_TITLE',
  EXPERIENCE_FLOW_OWN_CANDIDATES_QUESTION_SUBTITLE: 'EXPERIENCE_FLOW_OWN_CANDIDATES_QUESTION_SUBTITLE',
  EXPERIENCE_FLOW_RELIGION_QUESTION_TITLE: 'EXPERIENCE_FLOW_RELIGION_QUESTION_TITLE',
  EXPERIENCE_FLOW_LANGUAGES_QUESTION_TITLE: 'EXPERIENCE_FLOW_LANGUAGES_QUESTION_TITLE',
  EXPERIENCE_FLOW_LOCATION_QUESTION_SUBTITLE: 'EXPERIENCE_FLOW_LOCATION_QUESTION_SUBTITLE',
  EXPERIENCE_FLOW_OPPORTUNITIES_QUESTION_TITLE: 'EXPERIENCE_FLOW_OPPORTUNITIES_QUESTION_TITLE',
  EXPERIENCE_FLOW_OPPORTUNITIES_QUESTION_FORM_TITLE: 'EXPERIENCE_FLOW_OPPORTUNITIES_QUESTION_FORM_TITLE',

  EXPERIENCE_FLOW_MULTIPLE_SELECTION_TEXT: 'EXPERIENCE_FLOW_MULTIPLE_SELECTION_TEXT',
  EXPERIENCE_FLOW_MAX_SELECTION_TEXT: 'EXPERIENCE_FLOW_MAX_SELECTION_TEXT',

  EXPERIENCE_RECOMMENDATIONS_SECTION_NAME: 'EXPERIENCE_RECOMMENDATIONS_SECTION_NAME',
  EXPERIENCE_RECOMMENDATIONS_NO_RESULTS_TEXT: 'EXPERIENCE_RECOMMENDATIONS_NO_RESULTS_TEXT',

  EXPERIENCE_CANDIDATES_SECTION_NAME: 'EXPERIENCE_CANDIDATES_SECTION_NAME',
  EXPERIENCE_CANDIDATES_SECTION_SUBTITLE: 'EXPERIENCE_CANDIDATES_SECTION_SUBTITLE',
  EXPERIENCE_CANDIDATES_COUNT_TEXT: 'EXPERIENCE_CANDIDATES_COUNT_TEXT',
};

const TEXTS = {
  [TEXT_KEYS.INTERACTION_PENDING_VALIDATION]: {
    uy: () => 'Te enviamos un correo para validar que sos vos.',
    ar: () => 'Te enviamos un correo para validar que sos vos.',
    default: () => 'Te enviamos un correo para validar que eres tú.',
  },
  [TEXT_KEYS.INTERACTION_ADDED_CANDIDATE]: {
    params: ['schoolName'],
    pe: (params) => `Enviamos tu solicitud a ${params.schoolName} y lo agregamos a tu listado de opciones. Te avisaremos por email cuando el colegio responda.`,
    default: (params) => `Enviamos tu solicitud a ${params.schoolName} y lo agregamos a tu listado de candidatos. Te avisaremos por email cuando el centro responda.`,
  },
  [TEXT_KEYS.INTERACTION_CREATED]: {
    params: ['schoolName'],
    uy: (params) => `Enviamos tu solicitud a ${params.schoolName}. Te avisaremos por email cuando el centro responda.`,
    default: (params) => `Enviamos tu solicitud a ${params.schoolName}. Te avisaremos por email cuando el colegio responda.`,
  },
  [TEXT_KEYS.INTERACTION_FAILED]: {
    default: () => 'No pudimos procesar tu solicitud. Por favor, intenta nuevamente.',
  },
  [TEXT_KEYS.INTERACTION_ALREADY_CONTACTED]: {
    uy: () => 'Ya enviaste una consulta a este centro. Debes aguardar su respuesta.',
    default: () => 'Ya enviaste una consulta a este colegio. Debes aguardar su respuesta.',
  },
  [TEXT_KEYS.INTERACTION_ALREADY_CHATTING]: {
    uy: () => 'Ya tienes una conversación con este centro. Entra a tu área privada para continuar la comunicación.',
    default: () => 'Ya tienes una conversación con este colegio. Entra a tu área privada para continuar la comunicación.',
  },
  [TEXT_KEYS.INTERACTION_ALREADY_REQUESTED_INTERVIEW]: {
    uy: () => 'Ya solicitaste una entrevista con este centro. Debes aguardar su respuesta.',
    default: () => 'Ya solicitaste una entrevista con este colegio. Debes aguardar su respuesta.',
  },

  [TEXT_KEYS.HEADER_SEARCH_PLACEHOLDER]: {
    uy: () => 'Buscar por zona o centro educativo',
    default: () => 'Buscar por zona o colegio',
  },
  [TEXT_KEYS.HEADER_SEARCH_SCHOOL_RESULTS_TITLE]: {
    uy: () => 'Centros Educativos encontrados',
    default: () => 'Colegios encontrados',
  },
  [TEXT_KEYS.HEADER_SEARCH_LOCATION_RESULTS_CTA]: {
    params: ['locationName'],
    uy: (params) => `Ver centros ubicados en ${params.locationName}`,
    default: (params) => `Ver colegios ubicados en ${params.locationName}`,
  },
  [TEXT_KEYS.HEADER_LOGIN_SCHOOL]: {
    uy: () => 'Entrar como Institución',
    default: () => 'Entrar como Colegio',
  },

  [TEXT_KEYS.FOOTER_TEXT]: {
    uy: () => 'Encontrá el centro educativo ideal para tus hijos',
    default: () => 'Encuentra el colegio ideal para tus hijos',
  },

  [TEXT_KEYS.LANDING_HERO_TITLE_1]: {
    uy: () => 'Encontrá el',
    default: () => 'Encuentra el',
  },
  [TEXT_KEYS.LANDING_HERO_TITLE_2]: {
    uy: () => 'centro educativo',
    default: () => 'colegio',
  },
  [TEXT_KEYS.LANDING_HERO_TITLE_3]: {
    uy: () => 'ideal para tus hijos',
    default: () => 'ideal para tus hijos',
  },
  [TEXT_KEYS.LANDING_HERO_SUBTITLE_1]: {
    uy: () => 'Comenzá tu búsqueda con nuestras',
    default: () => 'Inicia tu búsqueda con nuestras',
  },
  [TEXT_KEYS.LANDING_HERO_SUBTITLE_2]: {
    uy: () => 'recomendaciones personalizadas',
    default: () => 'recomendaciones personalizadas',
  },
  [TEXT_KEYS.LANDING_HERO_CTA]: {
    uy: () => 'Comenzar búsqueda',
    default: () => 'Iniciar búsqueda',
  },
  [TEXT_KEYS.LANDING_BENEFITS_TITLE]: {
    pe: () => 'Gana un año de pensión escolar gratis usando VacantED',
    default: () => 'Ganá un año de colegio gratis usando VacantED',
  },
  [TEXT_KEYS.LANDING_BENEFITS_SUBTITLE]: {
    uy: () => 'Eligiendo centro educativo en VacantED accedés a importantes beneficios',
    default: () => 'Al elegir tu colegio en VacantED accedes a importantes beneficios',
  },

  [TEXT_KEYS.LANDING_HOWITWORKS_RECOMMENDATIONS_TITLE]: {
    uy: () => 'Obtené recomendaciones completando tu perfil de búsqueda',
    default: () => 'Obtén recomendaciones completando tu perfil de búsqueda',
  },
  [TEXT_KEYS.LANDING_HOWITWORKS_RECOMMENDATIONS_SUBTITLE]: {
    uy: () => 'Completá tus preferencias y te brindaremos recomendaciones personalizadas de centros educativos basadas en información específica de VacantED.',
    default: () => 'Completa tus preferencias y te brindaremos recomendaciones personalizadas de colegios basadas en información específica de VacantED.',
  },

  [TEXT_KEYS.LANDING_HOWITWORKS_CANDIDATES_TITLE]: {
    uy: () => 'Analizá tus candidatos, confirmá tu inscripción en VacantED y reclamá tus beneficios',
    pe: () => 'Analiza tus opciones, confirma tu matrícula en VacantED y accede a tus beneficios',
    default: () => 'Analiza tus candidatos, confirma tu matrícula en VacantED y accede a tus beneficios',
  },
  [TEXT_KEYS.LANDING_HOWITWORKS_CANDIDATES_SUBTITLE]: {
    uy: () => 'Cuando tomes la decisión, confirmá en nuestra web a qué centro educativo inscribiste y recibí los beneficios que tanto los centros como nosotros tenemos para vos.',
    default: () => 'Cuando tomes la decisión, confirma en nuestra web en qué colegio matriculaste y accede a los beneficios que tanto los colegios como nosotros tenemos para ti.',
  },

  [TEXT_KEYS.WHATSAPP_WIDGET_MESSAGE]: {
    uy: () => `👋 ¡Hola! Bienvenid@ a VacantED 🎓✨
¿Tenés dudas sobre cómo buscar centro educativo? 💬 Escribinos y te ayudamos 🙂`,
    default: () => `👋 ¡Hola! Bienvenid@ a VacantED 🎓✨
¿Tienes dudas sobre cómo buscar colegio? 💬 Escríbenos y te ayudaremos 🙂`,
  },

  [TEXT_KEYS.SCHOOL_LANDING_HEADER]: {
    uy: () => 'PARA CENTROS EDUCATIVOS',
    default: () => 'PARA COLEGIOS',
  },
  [TEXT_KEYS.SCHOOL_LANDING_TITLE]: {
    uy: () => 'Sumate a la mayor red de búsqueda',
    default: () => 'Únete a la mayor red de búsqueda',
  },
  [TEXT_KEYS.SCHOOL_LANDING_SUBTITLE]: {
    uy: () => 'Mejorá tu visibilidad, editá tu ficha informativa, recibí consultas y solicitudes de entrevista, y generá tu propio marketing dentro de VacantED. Contactanos!',
    default: () => 'Mejora tu visibilidad, edita tu ficha informativa, recibe consultas y solicitudes de entrevista, y genera tu propio marketing dentro de VacantED. Contáctanos!',
  },

  [TEXT_KEYS.SCHOOL_DIRECTORY_TITLE]: {
    params: ['neighborhood'],
    uy: (params) => `Centros educativos en ${params.neighborhood}`,
    default: (params) => `Colegios en ${params.neighborhood}`,
  },
  [TEXT_KEYS.SCHOOL_DIRECTORY_SUBTITLE]: {
    params: ['neighborhood'],
    uy: (params) => `Se incluyen los jardines, escuelas, liceos y colegios de ${params.neighborhood}`,
    default: (params) => `Incluye nidos, jardines, colegios con primaria y secundaria de ${params.neighborhood}`,
  },
  [TEXT_KEYS.SCHOOL_DIRECTORY_EMPTY_MESSAGE]: {
    uy: () => 'No se han encontrado centros educativos en este barrio.',
    default: () => 'No se han encontrado colegios en este barrio.',
  },

  [TEXT_KEYS.CARD_CTA_DISABLED]: {
    uy: () => 'No es posible solicitar entrevista con este centro a través de VacantED',
    default: () => 'No es posible solicitar entrevista con este colegio a través de VacantED',
  },

  [TEXT_KEYS.EXPERIENCE_FLOW_WELCOME]: {
    uy: () => 'búsqueda de centro educativo',
    default: () => 'búsqueda de colegio',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_STEP_1_TITLE]: {
    uy: () => 'Obtené recomendaciones',
    default: () => 'Obtén recomendaciones',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_STEP_1_SUBTITLE]: {
    uy: () => 'Ingresá tus preferencias y descubrí los centros más afines a tu búsqueda.',
    default: () => 'Ingresa tus preferencias y descubre los colegios que mejor se adaptan a tu búsqueda.',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_STEP_2_TITLE]: {
    pe: () => 'Analiza tus opciones',
    default: () => 'Analizá tus candidatos',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_STEP_2_SUBTITLE]: {
    uy: () => 'Podés compararlos, realizarles consultas y solicitar una entrevista.',
    default: () => 'Puedes compararlos, hacer consultas y solicitar una entrevista.',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_STEP_3_TITLE]: {
    uy: () => 'Confirmá tu inscripción',
    default: () => 'Confirma tu matrícula',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_STEP_3_SUBTITLE]: {
    uy: () => 'Informanos qué centro educativo elegiste y reclamá tus beneficios.',
    default: () => 'Infórmanos qué colegio elegiste y accede a tus beneficios.',
  },

  [TEXT_KEYS.EXPERIENCE_FLOW_START_YEAR_QUESTION_TITLE]: {
    uy: () => '¿Para cuándo estás buscando centro educativo?',
    default: () => '¿Para cuándo estás buscando colegio?',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_OWN_CANDIDATES_QUESTION_TITLE]: {
    uy: () => '¿Tenés centros educativos que ya estés considerando?',
    default: () => '¿Tienes colegios que ya estés considerando?',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_OWN_CANDIDATES_QUESTION_SUBTITLE]: {
    uy: () => 'Agregá a tu análisis los centros que te hayan recomendado amigos o familiares.',
    default: () => 'Agrega a tu análisis los colegios que te hayan recomendado amigos o familiares.',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_RELIGION_QUESTION_TITLE]: {
    uy: () => '¿Preferís que sea una institución religiosa o laica?',
    default: () => '¿Prefieres que sea una institución religiosa o laica?',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_LANGUAGES_QUESTION_TITLE]: {
    uy: () => '¿Qué idiomas debería enseñar el centro educativo?',
    default: () => '¿Qué idiomas debería enseñar el colegio?',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_LOCATION_QUESTION_SUBTITLE]: {
    uy: () => 'Ingresá una dirección para usar como centro de tu búsqueda.',
    default: () => 'Ingresa una dirección para usar como centro de tu búsqueda.',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_OPPORTUNITIES_QUESTION_TITLE]: {
    uy: () => '¿Te interesaría recibir alertas de cupos bonificados en centros que se ajusten a tu búsqueda?',
    default: () => '¿Te interesaría recibir alertas de vacantes bonificadas en colegios que se ajusten a tu búsqueda?',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_OPPORTUNITIES_QUESTION_FORM_TITLE]: {
    uy: () => 'Para comunicarte los cupos bonificados necesitamos los siguientes datos:',
    default: () => 'Para comunicarte las vacantes bonificadas necesitamos los siguientes datos:',
  },

  [TEXT_KEYS.EXPERIENCE_FLOW_MULTIPLE_SELECTION_TEXT]: {
    uy: () => 'Podés seleccionar más de una opción.',
    default: () => 'Puedes seleccionar más de una opción.',
  },
  [TEXT_KEYS.EXPERIENCE_FLOW_MAX_SELECTION_TEXT]: {
    uy: () => 'Seleccioná hasta 3 más de la lista.',
    default: () => 'Selecciona hasta 3 más de la lista.',
  },

  [TEXT_KEYS.EXPERIENCE_RECOMMENDATIONS_SECTION_NAME]: {
    uy: () => 'Recomendaciones de centros educativos',
    default: () => 'Recomendaciones de colegios',
  },
  [TEXT_KEYS.EXPERIENCE_RECOMMENDATIONS_NO_RESULTS_TEXT]: {
    params: ['schoolTypes'],
    uy: (params) => `No se encontraron centros educativos cercanos que coincidan con los tipos que seleccionaste (${params.schoolTypes}) y dicten los niveles educativos de tu búsqueda. Podés crear otro perfil de búsqueda con distintos criterios.`,
    default: (params) => `No se encontraron colegios cercanos que coincidan con los tipos que seleccionaste (${params.schoolTypes}) y brinden los niveles educativos de tu búsqueda. Puedes crear otro perfil de búsqueda con diferentes criterios.`,
  },

  [TEXT_KEYS.EXPERIENCE_CANDIDATES_SECTION_NAME]: {
    pe: () => 'Mis opciones',
    default: () => 'Mis candidatos',
  },
  [TEXT_KEYS.EXPERIENCE_CANDIDATES_SECTION_SUBTITLE]: {
    pe: () => 'Aquí se muestran los colegios que guardaste como opciones en tu búsqueda.',
    default: () => 'Aquí se muestran los centros que guardaste como candidatos en tu búsqueda.',
  },
  [TEXT_KEYS.EXPERIENCE_CANDIDATES_COUNT_TEXT]: {
    params: ['count'],
    pe: (params) => `${params.count} opciones`,
    default: (params) => `${params.count} candidatos`,
  },
};

const getLocalizedText = (textsKey, params = {}) => {
  const textsObject = TEXTS[textsKey];
  if (!textsObject) {
    throw new Error(`Texts key not found: ${textsKey}`);
  }
  if (textsObject.params?.length) {
    textsObject.params.forEach((requiredParam) => {
      if (params[requiredParam] === undefined || params[requiredParam] === null) {
        throw new Error(`Missing required parameter: ${requiredParam}`);
      }
    });
  }
  const countryCode = countryService.getCurrentCountryCode();
  return textsObject[countryCode] ? textsObject[countryCode](params) : textsObject.default(params);
};

export default {
  TEXT_KEYS,
  getLocalizedText,
};
